<template>
    <form class="wcd-tw-w-full wcd-tw-mb-2 sm:wcd-tw-mb-8">
        <h3 class="wcd-tw-text-teal-600 wcd-tw-font-bold wcd-tw-text-xl sm:wcd-tw-text-3xl wcd-tw-font-sans wcd-tw-text-center wcd-tw-my-2 sm:wcd-tw-my-0">Support Anaphylaxis UK by making a donation</h3>

        <fieldset class="wcd-tw-w-full wcd-tw-flex wcd-tw-flex-col sm:wcd-tw-flex-row wcd-tw-justify-center wcd-tw-items-center wcd-tw-my-5 sm:wcd-tw-my-10">
            <div
                v-for="(freq, key) in frequencies"
                :key="key"
                class="wcd-tw-w-full sm:wcd-tw-w-1/3 wcd-tw-mb-2 sm:wcd-tw-mb-0 wcd-tw-text-center"
            >
                <button
                    class="wcd-tw-cursor-pointer wcd-tw-capitalize wcd-tw-w-full sm:wcd-tw-w-auto sm:wcd-tw-mx-2 wcd-tw-text-sm wcd-tw-font-bold wcd-tw-border wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-p-3 wcd-tw-px-6 wcd-tw-rounded-full"
                    :class="{ 'wcd-tw-text-white wcd-tw-bg-orange-400': frequency === freq, 'wcd-tw-text-orange-400': frequency !== freq }"
                    @click.prevent="changeFrequency(freq)"
                >{{ freq }} donation</button>
            </div>
        </fieldset>

        <div>
            <h3 class="wcd-tw-text-black wcd-tw-font-bold wcd-tw-text-lg sm:wcd-tw-text-xl wcd-tw-font-sans wcd-tw-text-center">How much would you like to donate?</h3>

            <fieldset
                v-if="frequencyAmounts"
                class="wcd-tw-w-full wcd-tw-flex wcd-tw-flex-wrap wcd-tw-justify-between sm:wcd-tw-justify-center wcd-tw-items-start wcd-tw-my-5 sm:wcd-tw-my-10"
            >
                <div
                    v-for="am in frequencyAmounts"
                    :key="am.id"
                    class="wcd-tw-mb-2"
                >
                    <button
                        class="wcd-tw-cursor-pointer wcd-tw-capitalize sm:wcd-tw-mx-2 wcd-tw-text-xl sm:wcd-tw-text-2xl wcd-tw-font-extrabold wcd-tw-border wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-p-2 wcd-tw-px-8 sm:wcd-tw-px-10 wcd-tw-rounded-full"
                        :class="{ 'wcd-tw-text-white wcd-tw-bg-orange-400': amount == am.id, 'wcd-tw-text-orange-400': amount != am.id }"
                        @click.prevent="changeAmount(am)"
                    >&pound;{{ am.label }}</button>
                </div>

                <div class="wcd-tw-relative wcd-tw-basis-full sm:wcd-tw-basis-1/3 wcd-tw-w-full sm:wcd-tw-w-1/3 wcd-tw-mb-2">
                    <span
                        v-if="customAmount !== null"
                        class="wcd-tw-text-white wcd-tw-absolute wcd-tw-top-3 wcd-tw-left-8 wcd-tw-font-semibold"
                    >&pound;</span>
                    <input
                        v-if="custom"
                        v-model="customAmount"
                        type="number"
                        min="1"
                        step="1"
                        class="wcd-tr-max-w-full custom-donation wcd-tr-w-full wcd-tw-appearance-none wcd-tw-outline-none wcd-tw-cursor-pointer wcd-tw-capitalize wcd-tw-mt-3 sm:wcd-tw-mt-0 wcd-tw-ml-0 sm:wcd-tw-ml-2 sm:wcd-tw-mx-2 wcd-tw-font-extrabold wcd-tw-border wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-px-6 wcd-tw-pr-3 wcd-tw-rounded-full"
                        :class="{ 'wcd-tw-text-white wcd-tw-bg-orange-400 wcd-tw-text-2xl wcd-tw-py-2 wcd-tw-pl-10 wcd-tw-text-left': customAmount !== null && String(customAmount).trim() !== '', 'wcd-tw-text-sm wcd-tw-text-orange-400 wcd-tw-placeholder-orange-400 wcd-tw-py-3.5 wcd-tw-text-center': customAmount === null || String(customAmount).trim() === '' }"
                        style="max-width: 100%;"
                        :placeholder="customAmountPlaceholder"
                        @change.prevent="setCustomAmount(customAmount)"
                        @keyup="setCustomAmount(customAmount)"
                    />

                    <span
                        v-if="customAmount != null && ((customAmount < 0.3 && frequency !== 'monthly') || (customAmount < 3 && frequency === 'monthly'))"
                        class="block text-red-700 text-xs font-semibold text-center mt-2"
                    >
                        &pound;{{ frequency === 'monthly' ? '3.00' : '0.30' }} minimum donation
                    </span>
                </div>
            </fieldset>

            <div v-if="amount || (customAmount !== null)">
                <h3 class="wcd-tw-text-black wcd-tw-font-bold wcd-tw-text-lg sm:wcd-tw-text-xl wcd-tw-font-sans wcd-tw-text-center wcd-tw-mb-4">I would like to make my gift worth 25% more with Gift Aid</h3>
                <img
                    :src="image('assets/giftaidit.png')"
                    class="wcd-tw-block wcd-tw-w-52 wcd-tw-mx-auto wcd-tw-mt-8 wcd-tw-mb-4"
                />
                <p
                    v-if="donationAmount"
                    class="wcd-tw-text-center wcd-tw-w-full sm:wcd-tw-w-3/4 wcd-tw-text-xl wcd-tw-mx-auto wcd-tw-font-light"
                >Your donation of <strong class="wcd-tw-font-bold">&pound;{{ donationAmountString }}</strong> will be increased to <strong class="wcd-tw-font-bold">&pound;{{ giftAidAmount }}</strong> with Gift Aid at no extra cost to you.</p>

                <fieldset class="wcd-tw-flex wcd-tw-items-start wcd-tw-my-6">
                    <div class="wcd-tw-block">
                        <div class="wcd-tw-mt-1 wcd-tw-mr-2">
                            <label class="wcd-tw-inline-flex wcd-tw-items-center">
                                <input
                                    id="giftaid"
                                    v-model="giftaid"
                                    type="checkbox"
                                    name="giftaid"
                                    value="yes"
                                    class="wcd-tw-w-6 wcd-tw-h-6 wcd-tw-text-green-600 wcd-tw-border-0 wcd-tw-rounded-md focus:wcd-tw-ring-0"
                                />
                            </label>
                        </div>
                    </div>
                    <label
                        for="giftaid"
                        class="wcd-tw-text-2xs sm:wcd-tw-text-xs wcd-tw-text-gray-700"
                    >
                        <p class="wcd-tw-inline">Yes &ndash; I am a UK taxpayer and I would like Anaphylaxis UK to treat this donation (which is my own money, and doesn't include money from fundraising or collected from others) and any donations I make in the future or have made in the past four years as Gift Aid donations, until I notify you otherwise.</p>
                        <p class="wcd-tw-inline wcd-tw-ml-1">I understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year, it is my responsibility to pay any difference.</p>
                        <p class="wcd-tw-inline wcd-tw-ml-1">I also understand that Anaphylaxis UK will reclaim 25p of tax on every £1 that I give.</p>
                    </label>
                </fieldset>
            </div>

            <footer class="wcd-tw-w-full wcd-tw-flex wcd-tw-justify-center wcd-tw-pt-10 wcd-tw-pb-4 sm:wcd-tw-py-10">
                <button
                    class="wcd-tw-cursor-pointer wcd-tw-capitalize wcd-tw-w-full sm:wcd-tw-w-auto wcd-tw-mx-2 wcd-tw-text-sm wcd-tw-font-bold wcd-tw-border-2 wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-p-3 wcd-tw-px-8 wcd-tw-rounded-full wcd-tw-text-white wcd-tw-bg-orange-400"
                    :disabled="!canContinue"
                    :class="{ 'wcd-tw-opacity-50 wcd-tw-cursor-not-allowed': !canContinue }"
                    @click.prevent="nextStep"
                >continue</button>
            </footer>
        </div>
    </form>
</template>

<script>
export default {
    name: 'StepOne',

    props: {
        currentUser: {
            type: Object,
            required: false,
            default: {},
        },
        frequency: {
            type: String,
            required: true,
        },
        amounts: {
            type: Array,
            required: true,
        },
        amount: {
            type: Number,
            required: true,
        },
        custom: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    data () {
        return {
            frequencies: ['single', 'monthly'],
            customAmount: null,
            giftaid: true,
        };
    },

    watch: {
        giftaid: function (value) {
            this.giftAidChanged();
        },

        frequency: function (newValue, oldValue) {
            if (newValue != oldValue) {
                this.customAmount = null;
            }
        },
    },
    
    computed: {
        customAmountPlaceholder () {
            if (window.innerWidth && window.innerWidth < 400) {
                return 'Enter Custom Amount';
            }

            return 'Enter Amount';
        },

        frequencyAmounts () {
            if (!this.amounts || !this.amounts.length) {
                return [];
            }

            return this.amounts.filter(amount => amount.frequency === this.frequency).sort((a, b) => a.amount - b.amount);
        },

        currentUrl () {
            return encodeURIComponent(window.location.href);
        },

        donationAmount () {
            if (this.customAmount !== null && String(this.customAmount).trim() !== '') {
                return this.customAmount.toFixed(2);
            }

            if (!this.amounts || !this.amounts.length || !this.amount) {
                return null;
            }

            let value = this.amounts.filter(amount => amount.id == this.amount)[0];

            if (!value) {
                return null;
            }

            return value.amount.toFixed(2);
        },

        donationAmountString () {
            if (!this.donationAmount) {
                return '';
            }

            return this.donationAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        giftAidAmount () {
            if (!this.donationAmount) {
                return 0.00;
            }

            return (this.donationAmount * 1.25).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        canContinue () {
            if (!this.frequency) {
                return false;
            }

            if (!this.amount && this.customAmount === null) {
                return false;
            }

            if (this.customAmount !== null && String(this.customAmount).trim() !== '') {
                if (this.frequency === 'monthly' && parseFloat(this.customAmount) < 3) {
                    return false;
                } else if (parseFloat(this.customAmount) < 0.3) {
                    return false;
                }
            } else if (String(this.customAmount).trim() === '') {
                return false;
            }

            return true;
        },
    },

    methods: {
        image (uri) {
            return window.pluginUri + uri;
        },

        changeFrequency (frequency) {
            this.$emit('changeFrequency', frequency);
        },

        changeAmount (amount) {
            this.$emit('changeAmount', amount.id);
            this.customAmount = null;
            this.$emit('setCustomAmount', null);
        },

        setCustomAmount (amount) {
            if (amount !== null && String(amount).trim() !== '') {
                this.$emit('changeAmount', null);
                this.$emit('setCustomAmount', amount);
            } else {
                this.$emit('setCustomAmount', null);
            }
        },

        nextStep () {
            this.$emit('storeGiftAid', this.giftaid);
            this.$emit('changeStep', 2);
        },

        giftAidChanged () {
            this.$emit('storeGiftAid', this.giftaid);
        }
    },
};
</script>