<template>
    <form class="wcd-tw-w-full">
        <div
            v-if="!success && !error"
            class="wcd-tw-max-w-lg wcd-tw-mx-auto"
        >
            <h3 class="wcd-tw-text-teal-600 wcd-tw-font-bold wcd-tw-text-3xl wcd-tw-font-sans wcd-tw-text-center wcd-tw-mb-8">Please wait</h3>

            <p class="wcd-tw-text-md wcd-tw-my-1 wcd-tw-text-center">We're adding your donation to your cart...</p>
        </div>

        <div
            v-if="success"
            class="wcd-tw-max-w-lg wcd-tw-mx-auto"
        >
            <h3 class="wcd-tw-text-teal-600 wcd-tw-font-bold wcd-tw-text-3xl wcd-tw-font-sans wcd-tw-text-center wcd-tw-mb-8">Success</h3>

            <p class="wcd-tw-text-md wcd-tw-my-1 wcd-tw-text-center">Redirecting you to checkout!</p>
        </div>

        <div
            v-if="error"
            class="wcd-tw-max-w-lg wcd-tw-mx-auto"
        >
            <h3 class="wcd-tw-text-teal-600 wcd-tw-font-bold wcd-tw-text-3xl wcd-tw-font-sans wcd-tw-text-center wcd-tw-mb-8">Something went wrong</h3>

            <p class="wcd-tw-text-md wcd-tw-my-1 wcd-tw-text-center">We encountered issues creating your donation checkout. Please try again later</p>
        </div>
    </form>
</template>

<script>
export default {
    name: 'StepThree',

    props: {
        amount: {
            type: Number,
            required: true,
        },
        giftaid: {
            type: Boolean,
            required: true,
        },
        frequency: {
            type: String,
            required: true,
        },
        transactionId: {
            type: [String, Number],
            required: true,
        },
        amounts: {
            type: Array,
            required: true,
        },
        customAmount: {
            type: Number,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        consent: {
            type: Object,
            required: true,
        },
        note: {
            type: String,
            required: true,
        },
    },

    data () {
        return {
            success: false,
            error: true,
            attempts: 0,
        };
    },

    mounted () {
        this.store();
    },

    methods: {
        image (uri) {
            return window.pluginUri + uri;
        },

        changeAmount () {
            this.$emit('changeStep', 1);
        },

        previousStep () {
            this.$emit('changeStep', 2);
        },

        tryAgain () {
            this.attempts++;

        },

        async store () {
            this.success = false;
            this.error = false;

            let self = this;

            const status = await window.axios.post('/wp-json/raz_wcd/v1/woocommerce/add-to-cart?_wpnonce='+wp.nonce, {
                user: this.user,
                consent: this.consent,
                product_id: this.amount,
                custom_amount: this.customAmount,
                frequency: this.frequency,
                giftaid: this.giftaid,
                note: this.note,
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                self.error = true;
                self.success = false;
            });

            if (typeof (status.success) !== 'undefined') {
                this.success = true;

                let self = this;
                setTimeout(function () {
                    self.$emit('redirect');
                }, 2000);
            } else {
                this.success = false;
                this.error = true;
            }
        },
    },
};
</script>