<template>
    <nav class="relative wcd-tw-w-full wcd-tw-mb-4 sm:wcd-tw-mb-8">
      <div id="donation-top" class="absolute -top-40"></div>
      <ul class="wcd-tw-w-full wcd-tw-flex" >
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="wcd-tw-flex-grow wcd-tw-w-1/3 wcd-tw-flex"
          :class="{ 'ml-0': index === 0, 'ml-4': index > 0 }"
        >
          <span
            class="wcd-tw-block wcd-tw-w-full wcd-tw-px-0 wcd-tw-py-1 sm:wcd-tw-p-3 wcd-tw-text-center wcd-tw-font-bold wcd-tw-border-b-4 wcd-tw-border-solid"
            :class="{ 'wcd-tw-border-teal-600 wcd-tw-text-teal-600 wcd-tw-cursor-pointer': maxStep >= (index + 1), 'wcd-tw-cursor-not-allowed wcd-tw-text-gray-400 wcd-tw-border-gray-400': maxStep < (index + 1) }"
            @click.prevent="(index + 1) > maxStep ? null : changeStep(index + 1)"
          ><span class="wcd-tw-hidden sm:wcd-tw-inline">{{ index + 1 }}. </span><span v-html="step" /></span>
        </li>
      </ul>
    </nav>
</template>

<script>
export default {
  name: 'Nav',

  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    maxStep: {
      type: Number,
      required: true,
    },
  },

  data () {
    return {
      steps: [
        '<span class="wcd-tw-hidden sm:wcd-tw-inline">Your </span>Donation',
        '<span class="wcd-tw-hidden sm:wcd-tw-inline">Your </span>Details',
        'Payment',
      ],
    };
  },

  methods: {
    changeStep (step) {
      console.log('change step');
      this.$emit('changeStep', step);

      document.querySelector('#donation-top').scrollIntoView({
          behavior: 'smooth'
      });
    }
  }
}
</script>
