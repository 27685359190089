<template>
    <form class="wcd-tw-w-full wcd-tw-mt-5 sm:wcd-tw-mt-0">
        <div
            v-if="requiresLogin && presentingLoginModal"
            class="wcd-tw-fixed wcd-tw-inset-0 wcd-tw-h-full wcd-tw-w-full wcd-tw-bg-gray-300 wcd-tw-bg-opacity-50 wcd-tw-flex wcd-tw-items-center wcd-tw-justify-center wcd-tw-z-1000"
        >
            <div class="wcd-tw-bg-white wcd-tw-w-full wcd-tw-max-w-xl wcd-tw-p-10 wcd-tw-pt-8 wcd-tw-rounded-md">
                <h3 class="wcd-tw-text-primary wcd-tw-font-bold wcd-tw-text-2xl wcd-tw-text-center wcd-tw-mb-2">Welcome Back</h3>
                <p class="wcd-tw-text-sm wcd-tw-font-semibold wcd-tw-mb-2 text-center" style="line-height: 1.4rem;">It looks like you've used Anaphylaxis UK before!</p>
                <p class="wcd-tw-text-sm wcd-tw-mb-2 text-center" style="line-height: 1.4rem;">Because you've opted for a recurring monthly donation, we need to ensure you are able to change payment methods or cancel your subscription in future. As a result, we need you to login with your account details.</p>
                <form
                    class="wcd-tw-mt-6 wcd-tw-pt-6 wcd-tw-border-t wcd-tw-border-solid wcd-tw-border-gray-100"
                    @submit.prevent="loginFormSubmit"
                >
                    <p
                        v-if="loginError"
                        class="wcd-tw-text-red-800 wcd-tw-text-sm wcd-tw-text-center wcd-tw-mb-4 login-form-error"
                        style="line-height: 1.4rem;"
                        v-html="loginError"
                    />

                    <label
                        class="wcd-tw-text-sm wcd-tw-block wcd-tw-text-gray-600 wcd-tw-text-center wcd-tw-leading-6"
                        for="loginPassword"
                    >
                        Please enter the password for your account using <span class="wcd-tw-font-semibold">{{ user.email_address }}</span>:
                        <input
                            v-model="loginForm.password"
                            type="password"
                            name="loginPassword"
                            id="loginPassword"
                            class="wcd-tw-p-2 wcd-tw-w-full wcd-tw-block wcd-tw-border wcd-tw-border-solid wcd-tw-border-gray-200 wcd-tw-rounded-md wcd-tw-text-sm wcd-tw-mt-3"
                            placeholder="Your password"
                            required
                        />
                    </label>

                    <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-gap-2 wcd-tw-items-center wcd-tw-justify-center wcd-tw-mt-8">
                        <button
                            class="wcd-tw-cursor-pointer wcd-tw-capitalize wcd-tw-mx-auto wcd-tw-text-sm wcd-tw-font-bold wcd-tw-border-2 wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-p-3 wcd-tw-px-8 wcd-tw-rounded-full wcd-tw-text-white wcd-tw-bg-orange-400"
                            :disabled="!canLogin"
                            :class="{ 'wcd-tw-opacity-50 wcd-tw-cursor-not-allowed': !canLogin }"
                        >{{ loggingIn ? 'Logging in...' : 'Login' }}</button>

                        <span
                            class="wcd-tw-text-xs wcd-tw-text-gray-500 wcd-tw-underline wcd-tw-mt-4 wcd-tw-cursor-pointer hover:wcd-tw-no-underline"
                            @click.prevent="cancelLogin"
                        >Cancel and change your donation options</span>
                    </div>
                </form>
            </div>
        </div>

        <fieldset class="wcd-tw-flex wcd-tw-flex-col sm:wcd-tw-flex-row sm:wcd-tw-mb-8">
            <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-basis-full sm:wcd-tw-basis-auto wcd-tw-items-start wcd-tw-justify-start sm:wcd-tw-mr-4 wcd-tw-mb-4 sm:wcd-tw-mb-0">
                <label
                    for="title"
                    class="wcd-tw-text-sm wcd-tw-mb-2"
                >Title*</label>

                <select
                    id="title"
                    v-model="user.title"
                    name="title"
                    required
                    class="wcd-tw-bg-gray-100 wcd-tw-py-2.5 wcd-tw-p-2 wcd-tw-outline-orange-400 wcd-tw-text-md wcd-tw-w-full sm:wcd-tw-w-auto"
                >
                    <option
                        v-for="title in titles"
                        :key="title"
                    >{{ title }}</option>
                </select>
            </div>

            <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start sm:wcd-tw-mr-4 wcd-tw-mb-4 sm:wcd-tw-mb-0 wcd-tw-w-full sm:wcd-tw-w-1/3">
                <label
                    for="first_name"
                    class="wcd-tw-text-sm wcd-tw-mb-2"
                >First Name*</label>

                <input
                    id="first_name"
                    type="text"
                    v-model="user.first_name"
                    name="first_name"
                    required
                    class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                />
            </div>

            <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-mb-4 sm:wcd-tw-mb-0 wcd-tw-w-full sm:wcd-tw-w-1/3">
                <label
                    for="last_name"
                    class="wcd-tw-text-sm wcd-tw-mb-2"
                >Last Name*</label>

                <input
                    id="last_name"
                    type="text"
                    v-model="user.last_name"
                    name="last_name"
                    required
                    class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                />
            </div>
        </fieldset>

        <fieldset class="wcd-tw-flex wcd-tw-flex-col sm:wcd-tw-flex-row wcd-tw-mb-4 sm:wcd-tw-mb-8">
            <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-mb-4 sm:wcd-tw-mb-0 sm:wcd-tw-mr-4 wcd-tw-w-full sm:wcd-tw-w-1/3">
                <label
                    for="phone_number"
                    class="wcd-tw-text-sm wcd-tw-mb-2"
                >Phone Number</label>

                <input
                    id="phone_number"
                    type="text"
                    v-model="user.phone_number"
                    name="phone_number"
                    class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                />
            </div>

            <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-w-full sm:wcd-tw-w-1/3">
                <label
                    for="email_address"
                    class="wcd-tw-text-sm wcd-tw-mb-2"
                >Email Address*</label>

                <input
                    id="email_address"
                    type="email"
                    v-model="user.email_address"
                    name="email_address"
                    required
                    class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                    :class="{ 'wcd-tw-text-red-800 wcd-tw-outline-red-800 wcd-tw-border wcd-tw-border-solid wcd-tw-border-red-800': !emailIsValid && user.email_address }"
                />

                <span
                    v-if="!emailIsValid && user.email_address"
                    class="wcd-tw-text-red-800 wcd-tw-text-xs wcd-tw-mt-1"
                >Enter a valid email address</span>
            </div>
        </fieldset>

        <fieldset class="wcd-tw-flex wcd-tw-flex-col wcd-tw-mb-8">
            <p
                v-if="!manual_address_mode"
                class="wcd-tw-text-xs wcd-tw-mb-4"
            >Please enter your postcode below, and select your address from the selections.</p>

            <div
                v-if="!manual_address_mode"
                class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start sm:wcd-tw-mr-4 wcd-tw-mb-4"
            >
                <label
                    for="postcode"
                    class="wcd-tw-text-sm wcd-tw-mb-2"
                >Postcode*</label>

                <span
                    v-if="lookup_error"
                    class="block text-sm italic mb-2 text-red-700"
                >{{ lookup_error }}</span>

                <div class="wcd-tw-flex wcd-tw-w-full wcd-tw-flex-col sm:wcd-tw-flex-row wcd-tw-items-center">
                    <input
                        id="postcode"
                        type="text"
                        v-model="user.postcode"
                        name="postcode"
                        maxlength="8"
                        required
                        class="wcd-tw-bg-gray-100 wcd-tw-p-2 wcd-tw-outline-orange-400 wcd-tw-w-full sm:wcd-tw-w-auto"
                    />

                    <button
                        v-if="!loading"
                        class="wcd-tw-cursor-pointer wcd-tw-capitalize wcd-tw-w-full sm:wcd-tw-w-auto wcd-tw-mt-4 sm:wcd-tw-mt-0 sm:wcd-tw-ml-4 sm:wcd-tw-mr-4 wcd-tw-text-xs wcd-tw-font-bold wcd-tw-border-2 wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-p-3 sm:wcd-tw-p-2 wcd-tw-px-4 wcd-tw-rounded-full wcd-tw-text-white wcd-tw-bg-orange-400"
                        :disabled="!user.postcode || user.postcode.length < 3"
                        :class="{ 'wcd-tw-opacity-50 wcd-tw-cursor-not-allowed': !user.postcode || user.postcode.length < 3 }"
                        @click.prevent="addressLookup"
                    >find address</button>

                    <select
                        v-if="!loading && addresses && !manual_address_mode"
                        v-model="address"
                        class="wcd-tw-text-sm wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-w-full sm:wcd-tw-w-auto wcd-tw-mt-4 sm:wcd-tw-mt-0"
                    >
                        <option :value="null">Select an address</option>
                        <option
                            v-for="(address, key) in addresses"
                            :key="key"
                            :value="address"
                        >{{ address.label }}</option>
                    </select>

                    <span
                        v-if="loading"
                        class="text-sm italic ml-2"
                    >loading...</span>
                </div>
            </div>

            <span
                v-if="!manual_address_mode"
                class="wcd-tw-text-sm wcd-tw-text-black wcd-tw-underline wcd-tw-cursor-pointer hover:wcd-tw-text-orange-400"
                @click.prevent="enterManualAddressMode"
            >Enter address manually</span>

            <div
                v-if="manual_address_mode"
                class="wcd-tw-flex wcd-tw-flex-col sm:wcd-tw-flex-row sm:wcd-tw-flex-wrap wcd-tw-mb-2 sm:wcd-tw-mb-8"
            >
                <div class="wcd-tw-flex wcd-tw-basis-full sm:wcd-tw-basis-auto wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-mr-4 wcd-tw-mb-4 wcd-tw-w-full sm:wcd-tw-w-1/3">
                    <label
                        for="address_line_1"
                        class="wcd-tw-text-sm wcd-tw-mb-2"
                    >Address Line 1*</label>

                    <input
                        id="address_line_1"
                        type="text"
                        v-model="user.address_line_1"
                        name="address_line_1"
                        required
                        class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                    />
                </div>

                <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-mr-4 wcd-tw-mb-4 wcd-tw-w-full sm:wcd-tw-w-1/3">
                    <label
                        for="address_line_2"
                        class="wcd-tw-text-sm wcd-tw-mb-2"
                    >Address Line 2</label>

                    <input
                        id="address_line_2"
                        type="text"
                        v-model="user.address_line_2"
                        name="address_line_2"
                        class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                    />
                </div>

                <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-mr-4 wcd-tw-mb-4 wcd-tw-w-full sm:wcd-tw-w-1/3">
                    <label
                        for="town"
                        class="wcd-tw-text-sm wcd-tw-mb-2"
                    >Town/City*</label>

                    <input
                        id="town"
                        type="text"
                        v-model="user.town"
                        name="town"
                        required
                        class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                    />
                </div>

                <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-mr-4 wcd-tw-mb-4 wcd-tw-w-full sm:wcd-tw-w-1/3">
                    <label
                        for="postcode"
                        class="wcd-tw-text-sm wcd-tw-mb-2"
                    >Postcode*</label>

                    <input
                        id="postcode"
                        type="text"
                        v-model="user.postcode"
                        name="postcode"
                        maxlength="8"
                        required
                        class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                    />
                </div>
            </div>
        </fieldset>

        <fieldset class="wcd-tw-flex wcd-tw-flex-col wcd-tw-mb-8">
            <div class="wcd-tw-flex wcd-tw-flex-col wcd-tw-items-start wcd-tw-justify-start wcd-tw-mb-4 sm:wcd-tw-mb-0 sm:wcd-tw-mr-4 wcd-tw-w-full">
                <label
                    for="note"
                    class="wcd-tw-text-sm wcd-tw-mb-2"
                >Add a note to your donation (max 150 characters):</label>

                <textarea
                    id="note"
                    rows="2"
                    v-model="note"
                    name="note"
                    maxlength="150"
                    class="wcd-tw-bg-gray-100 wcd-tw-p-3 wcd-tw-outline-orange-400 wcd-tw-text-sm wcd-tw-w-full"
                />
            </div>
        </fieldset>


        <fieldset class="wcd-tw-flex wcd-tw-flex-col wcd-tw-mb-8">
            <p class="wcd-tw-text-sm wcd-tw-font-bold wcd-tw-mb-4">Please keep me up to date by:</p>

            <div class="wcd-tw-flex wcd-tw-flex-col sm:wcd-tw-flex-row wcd-tw-justify-center sm:wcd-tw-justify-start wcd-tw-items-start sm:wcd-tw-items-center">
                <fieldset class="wcd-tw-flex wcd-tw-w-full sm:wcd-tw-w-auto wcd-tw-mb-2 sm:wcd-tw-mb-0 sm:wcd-tw-mr-12 wcd-tw-justify-start wcd-tw-items-center">
                    <p class="mb-0 wcd-tw-text-sm wcd-tw-w-1/3 sm:wcd-tw-w-auto wcd-tw-mr-3">Email:</p>

                    <input
                        id="consent_email_yes"
                        type="radio"
                        v-model="consent.email_consent"
                        :value="true"
                        class="hidden"
                    />
                    <label
                        for="consent_email_yes"
                        class="wcd-tw-text-sm wcd-tw-mr-3"
                    >
                        <span class="wcd-tw-relative wcd-tw-top-0.5 wcd-tw-w-4 wcd-tw-h-4 wcd-tw-inline-block wcd-tw-mr-0.5 wcd-tw-rounded-full wcd-tw-border wcd-tw-border-gray-500 wcd-tw-flex-no-shrink"></span>
                        Yes
                    </label>

                    <input
                        id="consent_email_no"
                        type="radio"
                        v-model="consent.email_consent"
                        :value="false"
                        class="hidden"
                    />
                    <label
                        for="consent_email_no"
                        class="text-sm"
                    >
                        <span class="wcd-tw-relative wcd-tw-top-0.5 wcd-tw-w-4 wcd-tw-h-4 wcd-tw-inline-block wcd-tw-mr-0.5 wcd-tw-rounded-full wcd-tw-border wcd-tw-border-gray-500 wcd-tw-flex-no-shrink"></span>
                        No
                    </label>
                </fieldset>

                <fieldset class="wcd-tw-flex wcd-tw-w-full sm:wcd-tw-w-auto wcd-tw-mb-2 sm:wcd-tw-mb-0 sm:wcd-tw-mr-12 wcd-tw-justify-start wcd-tw-items-center">
                    <p class="mb-0 wcd-tw-text-sm wcd-tw-w-1/3 sm:wcd-tw-w-auto wcd-tw-mr-3">Phone:</p>

                    <input
                        id="consent_phone_yes"
                        type="radio"
                        v-model="consent.phone_consent"
                        :value="true"
                        class="hidden"
                    />
                    <label
                        for="consent_phone_yes"
                        class="wcd-tw-text-sm wcd-tw-mr-3"
                    >
                        <span class="wcd-tw-relative wcd-tw-top-0.5 wcd-tw-w-4 wcd-tw-h-4 wcd-tw-inline-block wcd-tw-mr-0.5 wcd-tw-rounded-full wcd-tw-border wcd-tw-border-gray-500 wcd-tw-flex-no-shrink"></span>
                        Yes
                    </label>

                    <input
                        id="consent_phone_no"
                        type="radio"
                        v-model="consent.phone_consent"
                        :value="false"
                        class="hidden"
                    />
                    <label
                        for="consent_phone_no"
                        class="text-sm"
                    >
                        <span class="wcd-tw-relative wcd-tw-top-0.5 wcd-tw-w-4 wcd-tw-h-4 wcd-tw-inline-block wcd-tw-mr-0.5 wcd-tw-rounded-full wcd-tw-border wcd-tw-border-gray-500 wcd-tw-flex-no-shrink"></span>
                        No
                    </label>
                </fieldset>

                <fieldset class="wcd-tw-flex wcd-tw-w-full sm:wcd-tw-w-auto wcd-tw-justify-start wcd-tw-items-center">
                    <p class="mb-0 wcd-tw-text-sm wcd-tw-w-1/3 sm:wcd-tw-w-auto wcd-tw-mr-3">Text:</p>

                    <input
                        id="consent_text_yes"
                        type="radio"
                        v-model="consent.sms_consent"
                        :value="true"
                        class="hidden"
                    />
                    <label
                        for="consent_text_yes"
                        class="wcd-tw-text-sm wcd-tw-mr-3"
                    >
                        <span class="wcd-tw-relative wcd-tw-top-0.5 wcd-tw-w-4 wcd-tw-h-4 wcd-tw-inline-block wcd-tw-mr-0.5 wcd-tw-rounded-full wcd-tw-border wcd-tw-border-gray-500 wcd-tw-flex-no-shrink"></span>
                        Yes
                    </label>

                    <input
                        id="consent_text_no"
                        type="radio"
                        v-model="consent.sms_consent"
                        :value="false"
                        class="hidden"
                    />
                    <label
                        for="consent_text_no"
                        class="wcd-tw-text-sm"
                    >
                        <span class="wcd-tw-relative wcd-tw-top-0.5 wcd-tw-w-4 wcd-tw-h-4 wcd-tw-inline-block wcd-tw-mr-0.5 wcd-tw-rounded-full wcd-tw-border wcd-tw-border-gray-500 wcd-tw-flex-no-shrink"></span>
                        No
                    </label>
                </fieldset>
            </div>
        </fieldset>

        <div>
            <p class="wcd-tw-text-gray-700 wcd-tw-text-xs wcd-tw-font-light">By providing us with any personal information you consent to the terms and conditions of our Privacy Policy available at www.anaphylaxis.org.uk. We will never sell your personal data and we will never share it with another company or charity for marketing purposes. If you change your mind you can unsubscribe at any time or contact our Data Protection Officer via privacy@anaphylaxis.org.uk, call +44 (0)1252 546100 or write to Data Protection Officer, Anaphylaxis UK, 1 Alexandra Road, Farnborough, GU14 6BU.</p>
        </div>
        
        <footer class="wcd-tw-w-full wcd-tw-flex wcd-tw-justify-center wcd-tw-py-10">
            <button
                class="wcd-tw-cursor-pointer wcd-tw-capitalize wcd-tw-mx-2 wcd-tw-text-sm wcd-tw-font-bold wcd-tw-border-2 wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-p-3 wcd-tw-px-8 wcd-tw-rounded-full wcd-tw-bg-white wcd-tw-text-orange-400 hover:wcd-tw-text-white hover:wcd-tw-bg-orange-400"
                @click.prevent="previousStep"
            >back</button>

            <button
                class="wcd-tw-cursor-pointer wcd-tw-capitalize wcd-tw-mx-2 wcd-tw-text-sm wcd-tw-font-bold wcd-tw-border-2 wcd-tw-border-solid wcd-tw-border-orange-400 wcd-tw-p-3 wcd-tw-px-8 wcd-tw-rounded-full wcd-tw-text-white wcd-tw-bg-orange-400"
                :disabled="!canContinue"
                :class="{ 'wcd-tw-opacity-50 wcd-tw-cursor-not-allowed': !canContinue }"
                @click.prevent="nextStep"
            >{{ checkingEmail ? 'Checking user...' : 'continue' }}</button>
        </footer>
    </form>
</template>

<script>
export default {
    name: 'StepTwo',

    props: {
        currentUser: {
            type: Object,
            required: false,
            default: {},
        },
        currentUserConsent: {
            type: Object,
            required: false,
            default: {},
        },
        getaddresskey: {
            type: String,
            required: true,
        },
        frequency: {
            type: String,
            required: true,
        },
    },

    data () {
        return {
            titles: ['Mr', 'Mrs', 'Ms', 'Miss', 'Master', 'Dr'],
            user: {
                id: null,
                title: null,
                first_name: null,
                last_name: null,
                phone_number: null,
                email_address: null,
                address_line_1: null,
                address_line_2: null,
                town: null,
                postcode: null,
                password: null,
                password_confirmation: null,
            },
            consent: {
                email_consent: true,
                phone_consent: true,
                sms_consent: true,
                ways_to_help: false,
                way_to_engage: false,
                offers: false,
            },
            note: null,
            manual_address_mode: false,
            loading: false,
            lookup_error: null,
            addresses: null,
            address: null,
            forceRegistration: false,
            requiresLogin: false,
            presentingLoginModal: false,
            checkingEmail: false,
            loggingIn: false,
            loginForm: {
                username: '',
                password: '',
            },
            loginError: null,
        };
    },

    watch: {
        address: function (value) {
            if (value) {
                this.user.address_line_1 = value.address_line_1;
                this.user.address_line_2 = value.address_line_2;
                this.user.town = value.town;
                this.user.postcode = value.postcode;

                this.enterManualAddressMode();
            }
        },
    },

    mounted () {
        this.parseCurrentUser();

        if (this.user.address_line_1) {
            this.enterManualAddressMode();
        }
    },

    computed: {
        isLoggedIn () {
            return this.currentUser && typeof this.currentUser.id !== 'undefined' && this.currentUser.id !== 0;
        },

        passwordIsLongEnough () {
            return this.user.password && this.user.password.length >= 8;
        },

        passwordContainsOneUppercaseLetter () {
            return /[A-Z]/.test(this.user.password);
        },

        passwordContainsOneNumber () {
            return /[0-9]/.test(this.user.password);
        },

        passwordIsValid () {
            return this.passwordIsLongEnough && this.passwordContainsOneUppercaseLetter && this.passwordContainsOneNumber;
        },

        passwordConfirmationIsValid () {
            return this.passwordIsValid && this.user.password === this.user.password_confirmation;
        },

        requiresRegistration () {
            return this.forceRegistration && this.frequency === 'monthly' && !this.currentUser.id;
        },

        userIsValid () {
            if (!this.user.title || this.user.title.trim() === '') {
                return false;
            }

            if (!this.user.first_name || this.user.first_name.trim() === '') {
                return false;
            }

            if (!this.user.last_name || this.user.last_name.trim() === '') {
                return false;
            }

            if (!this.user.email_address || this.user.email_address.trim() === '' || !this.emailIsValid) {
                return false;
            }

            if (!this.user.address_line_1 || this.user.address_line_1.trim() === '') {
                return false;
            }

            if (!this.user.town || this.user.town.trim() === '') {
                return false;
            }

            if (!this.user.postcode || this.user.postcode.trim() === '') {
                return false;
            }

            if (this.requiresRegistration && (!this.passwordConfirmationIsValid || !this.passwordIsValid)) {
                return false;
            }

            return true;
        },

        emailIsValid () {
            if (!this.user.email_address) {
                return false;
            }

            if (this.user.email_address.trim() === '') {
                return false;
            }

            if (this.requiresLogin) {
                return false;
            }

            return String(this.user.email_address)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },

        canContinue () {
            return this.userIsValid;
        },

        canLogin () {
            return this.loginForm.username.trim() != '' && this.loginForm.password.trim() != '';
        },
    },

    methods: {
        cancelLogin () {
            this.requiresLogin = false;
            this.presentingLoginModal = false;
            this.$emit('changeStep', 1);
        },

        async loginFormSubmit () {
            this.loggingIn = true;
            this.loginError = null;

            const user = await window.axios.post('/wp-json/raz_wcd/v1/woocommerce/login?_wpnonce='+wp.nonce, this.loginForm).then((res) => {
                return res.data;
            }).catch((err) => {
                let res = {
                    logged_in: false,
                    error: null,
                };

                if (typeof err.response.data.error !== 'undefined' && err.response.data.error) {
                    res.error = err.response.data.error;
                } else {
                    res.error = 'We could not log you in at this time. Please check your email address and password are correct.';
                }

                return res;
            });

            this.loggingIn = false;

            if (user && typeof user.logged_in !== 'undefined' && user.logged_in) {
                this.requiresLogin = false;
                this.presentingLoginModal = false;
                this.$emit('updateUserId', user.id);
                this.user.id = user.id;
                wp.nonce = user.nonce;

                this.updateUser();
                this.updateConsent();
                this.updateNote();
                this.$emit('changeStep', 3);
            } else {
                if (typeof user.error !== 'undefined' && user.error) {
                    this.loginError = user.error;
                } else {
                    this.loginError = 'We could not log you in at this time. Please check your email address and password are correct.';
                }
            }

            return user;
        },

        parseCurrentUser () {
            if (!this.currentUser) {
                return;
            }
            this.consent = this.currentUserConsent;
            this.user = this.currentUser;
        },

        async checkUserEmail () {
            this.checkingEmail = true;

            const user = await window.axios.post('/wp-json/raz_wcd/v1/woocommerce/email?_wpnonce='+wp.nonce, {
                email_address: this.user.email_address,
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return {
                    exists: false,
                };
            });

            this.checkingEmail = false;

            return user;
        },

        updateUser () {
            this.$emit('updateUser', this.user);
        },

        updateConsent () {
            this.$emit('updateConsent', this.consent);
        },

        updateNote () {
            this.$emit('updateNote', this.note);
        },

        previousStep () {
            this.presentingLoginModal = false;
            this.requiresLogin = false;

            this.updateUser();
            this.updateConsent();
            this.$emit('changeStep', 1);
        },

        nextStep () {
            // if (!this.isLoggedIn && this.frequency === 'monthly') {
            //     const user = await this.checkUserEmail();

            //     if (user.exists) {
            //         this.loginForm.username = this.user.email_address;
            //         this.requiresLogin = true;
            //         this.presentingLoginModal = true;

            //         return;
            //     }
            // }
            
            this.presentingLoginModal = false;
            this.requiresLogin = false;

            this.updateUser();
            this.updateConsent();
            this.updateNote();
            this.$emit('changeStep', 3);
        },

        enterManualAddressMode () {
            this.manual_address_mode = true;
        },

        exitManualAddressMode () {
            this.manual_address_mode = false;
        },

        async addressLookup () {
            this.lookup_error = null;
            this.loading = true;
            this.addresses = null;
            this.exitManualAddressMode();

            await window.axios('https://api.getAddress.io/find/'+this.user.postcode+'?api-key='+this.getaddresskey+'&expand=true')
                .then((response) => {
                    this.addresses = [];
                    if (response.data.addresses.length) {
                        response.data.addresses.forEach((address) => {
                            this.addresses.push({
                                label: address.line_1,
                                address_line_1: address.line_1,
                                address_line_2: address.line_2,
                                town: address.town_or_city,
                                postcode: response.data.postcode,
                            });
                        });
                    }

                    this.loading = false;
                }).catch((err) => {
                    if (typeof err.response.data.Message !== 'undefined') {
                        this.lookup_error = err.response.data.Message.replace('Bad Request: ', '') + ' Please retry or enter manually.';
                    } else if (typeof err.response.status !== 'undefined') {
                        switch (err.response.status) {
                            case 400:
                            case 404:
                                this.lookup_error = 'Your address could not be found. Please retry or enter manually.';
                                break;

                            case 500:
                                this.lookup_error = 'There was an error looking up your address. Please try entering manually';
                                this.enterManualAddressMode();
                        }
                    } else {
                        this.lookup_error = 'There was an error looking up your address. Please try entering manually';
                        this.enterManualAddressMode();
                    }

                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="css">
input[type="radio"] + label span {
    transition: background .2s, transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
    transform: scale(1.1);
} 

input[type="radio"]:checked + label span {
    background-color: #db6a00;
    box-shadow: 0 0 0 2px white inset;
}

input[type="radio"]:checked + label{
    color: #db6a00;
}

.login-form-error strong {
    font-weight: 600 !important;
}

.login-form-error a {
    text-decoration: underline !important;
    font-weight: 600 !important;
}
</style>
