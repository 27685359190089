import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import "./css/tailwind.css";

window.pluginUri = '/wp-content/plugins/woocommerce-donations/src/';
window.axios = axios;

const mountElement = document.querySelector("#RazDonationsVueJs");
if (mountElement) {
	const app = createApp(App, { ...mountElement.dataset}).mount(mountElement);
}