<template>
  <div class="w-full max-w-3xl mx-auto my-10">
    <Nav
      :currentStep="step"
      :maxStep="maxStep"
      @change-step="changeStep"
    />

    <StepOne
      v-if="step === 1"
      :currentUser="user"
      :amounts="amounts"
      :custom="allowCustomAmount"
      :frequency="frequency"
      :amount="amount"
      :custom-amount="customAmount"
      @change-frequency="changeFrequency"
      @change-amount="changeAmount"
      @set-custom-amount="setCustomAmount"
      @change-max-step="changeMaxStep"
      @change-step="changeStep"
      @store-gift-aid="storeGiftAid"
    />

    <StepTwo
      v-if="step === 2"
      :currentUser="user"
      :currentUserConsent="consent"
      :getaddresskey="getaddresskey"
      :frequency="frequency"
      @change-step="changeStep"
      @update-user="updateUser"
      @update-consent="updateConsent"
      @update-note="updateNote"
      @update-user-id="updateUserId"
    />

    <StepThree
      v-if="step === 3"
      :amount="amount"
      :giftaid="giftaid"
      :amounts="amounts"
      :customAmount="customAmount"
      :frequency="frequency"
      :user="user"
      :consent="consent"
      :note="note"
      @redirect="redirect"
    />
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import StepOne from './components/steps/StepOne.vue'
import StepTwo from './components/steps/StepTwo.vue'
import StepThree from './components/steps/StepThree.vue'

export default {
  name: "RazDonationsVueJs",

  components: {
    Nav,
    StepOne,
    StepTwo,
    StepThree,
  },

  props: [
    'presets',
    'getaddresskey'
  ],

  data () {
    return {
      step: 1,
      maxStep: 1,
      amounts: null,
      allowCustomAmount: true,
      frequency: 'single',
      amount: null,
      customAmount: null,
      giftaid: false,
      user: {},
      consent: {},
      note: null,
    };
  },

  mounted () {
    this.parseDefaultUserObject();
    this.parseDefaultAmountsArray();
    this.parseDefaultCustomBool();
  },

  methods: {
    parseDefaultUserObject () {
      this.user = JSON.parse(this.presets).user;
      this.consent = JSON.parse(this.presets).consent;
    },

    parseDefaultAmountsArray () {
      this.amounts = Object.freeze(JSON.parse(this.presets).amounts);
      this.amount = this.amounts.filter(a => a.amount == 10 && a.frequency === 'single')[0].id;
    },

    parseDefaultCustomBool () {
      this.allowCustomAmount = Boolean(JSON.parse(this.presets).custom);
    },

    changeStep (step) {
      this.step = step;

      document.querySelector('#donation-top').scrollIntoView({
          behavior: 'smooth'
      });

      if (step > this.maxStep) {
        this.changeMaxStep(step);
      }
    },

    changeMaxStep (step) {
      this.maxStep = step;
    },

    changeFrequency (frequency) {
      this.frequency = frequency;

      this.customAmount = null;
      this.amount = this.amounts.filter(a => a.amount == 10 && a.frequency === frequency)[0].id;
    },

    changeAmount (amount) {
      this.amount = amount;
    },

    setCustomAmount (amount) {
      this.customAmount = amount;
    },

    storeGiftAid (giftaid) {
      this.giftaid = giftaid;
    },

    updateUser (user) {
      this.user = user;
    },

    updateConsent (consent) {
      this.consent = consent;
    },

    updateNote (note) {
      this.note = note;
    },

    updateUserId (id) {
      this.user.id = id;
    },

    async redirect () {
      window.location = '/checkout/';
    },
  },
};
</script>
